import React from 'react';
import Header from './../../components/Header/Header';
import './Home.sass';
import Boss from './Boss.png';
import EPulpit from './e-pulpit24_color 1.png';
import BluSerwer from './bluserwer.webp';
import Blusign from './blusign.webp';
import Blustreamtv from './blustreamtv.webp';
import BlustreamAcad from './blustream_academy.webp';
import BluOffice from './bluoffice.webp';

import { withTranslation } from 'react-i18next';
import BrandCard from '../../components/BrandCard/BrandCard';
import Footer from '../../components/Footer/Footer';

class Home extends React.Component {
	#projectDescRef = React.createRef();
	#investPackage = React.createRef();
	#discuss = React.createRef();
	#documents = React.createRef();
	#investList = React.createRef();
	#risks = React.createRef();
	#ourInvestments = React.createRef();

	componentDidMount() {
		document.title = "BluSoft";
	}

	render() {
		const { t } = this.props;
		return (
			<div className="Home">
				<Header />
				<br /><br /><br /><br />
				<section className="section" id="o-nas">
					<div className="w100 flex justify-center">
						<h1>Czym jest BluSoft?</h1>
					</div>
					<div className="section__block__boss section__block--white">
						<div>
							<p><a href="https://blusoft.pl">BluSoft</a> to owoc pasji i zainteresowań, które zrodziły się we mnie wiele lat temu. Związany jestem z branżą informatyczną przez już dwie dekady, z czego ponad sześć lat spędziłem w dynamicznym świecie bankowości. Moja droga zawodowa prowadziła mnie również przez przemysł samochodowy, gdzie miałem przyjemność pracować dla renomowanych firm takich jak Volvo, Mercedes czy Delphi Automotive. BluSoft to zespół ekspertów, którzy pasją napędzają innowacje. Specjalizujemy się w zarządzaniu serwerami, hostingiem serwerów dedykowanych oraz kompleksową obsługą informatyczną firm. Nasze rozwiązania obejmują systemy CRM, kadrowo-księgowe oraz systemy wspierające efektywne zarządzanie przedsiębiorstwem. Współczesny rynek wymaga elastyczności i mobilności, dlatego też tworzymy rozwiązania dopasowane do potrzeb dzisiejszych czasów. Nasza najnowsza inicjatywa to „Komputer tam gdzie Ty” - nowoczesna "Chmura" obliczeniowa <a href="https://e-pulpit24.pl">e-Pulpit24.pl</a>, która umożliwia pracę mobilną i bezpieczną, eliminując zbędne koszty początkowe. Dzięki naszym rozwiązaniom firmy mogą pracować szybko i wydajnie, niezależnie od lokalizacji czy urządzenia.</p>
						</div>
						<div className="boss">
							<img draggable={false} src={Boss} alt="Prezes Krzysztof Błaszkiewicz" />
							<p><span className="text--handwritten">{t("boss")}</span></p>
						</div>
					</div>
					<div className="section__block section__block--white text-center">
						Informatyka i nowe technologie to nie tylko praca, to nasza pasja, która popycha nas naprzód. BluSoft to nie tylko firma - to wspólnota ludzi gotowych na jutro.
						<br />
						<br />
						Zapoznaj się z szeroką ofertą firmy BluSoft poniżej.
					</div>
				</section>
				<section className="section--gray">
					<div className="w100 flex justify-center">
						<h2>BluSoft - Nowy wymiar efektywności biznesu</h2>
					</div>
					<div className="section__block--transparent">
						W różnych branżach efektywność jest kluczem do sukcesu. <a href="https://blusoft.pl">BluSoft.pl</a> oferuje rozwiązania, które pozwalają na osiągnięcie optymalnej wydajności w każdym obszarze.

						Przenosząc zadania z różnych dziedzin na centralne serwery, firmy zyskują możliwość lepszej organizacji pracy biura. To samo dotyczy branż, takich jak produkcja, handel czy usługi. Wykorzystaj technologie <a href="https://blusoft.pl">BluSoft.pl</a>, aby zredukować koszty i zwiększyć efektywność.
						<br />
						<br />
						Nowi pracownicy mogą od razu rozpocząć pracę na gotowym środowisku. Bez konieczności specjalistycznego sprzętu, zyskujesz prostsze i bardziej zorganizowane zarządzanie.
						<br />
						<br />
						Współczesny rytm życia wymaga elastyczności. Dzięki narzędziom takim jak e-Pulpit24 od <a href="https://blusoft.pl">BluSoft.pl</a>, praca może być wykonywana w dowolnym czasie i miejscu. Niezależnie od branży, mobilność jest kluczem do sukcesu.
						<br />
						<br />
						Awarie sprzętu mogą zdarzyć się w najmniej odpowiednim momencie. Dlatego warto zaufać <a href="https://blusoft.pl">BluSoft.pl</a> i przechowywać dane w chmurze. Bezpieczeństwo i dostępność gwarantowane.
						<br />
						<br />
						Optymalizacja oprogramowania w trakcie działań biznesowych jest kluczowa, bez względu na branżę. e-Pulpit24 ułatwia aktualizacje i dostosowywanie do bieżących potrzeb.
						<br />
						<br />
						Wnioski i efekty pracy w jednym miejscu są nieocenione. <a href="https://blusoft.pl">BluSoft.pl</a> gwarantuje, że finalna prezentacja będzie łatwa i intuicyjna.
						<br />
						<br />
						Optymalizuj swoją działalność w dowolnej branży z <a href="https://blusoft.pl">BluSoft.pl</a> i ciesz się nowym poziomem efektywności. Wykorzystaj nasze rozwiązania już teraz!
						<br />
						<br />
						<div className="w100 flex justify-center">
							<h2>Zwiększ Efektywność Biznesu z <a href="https://www.blusoft.pl/">BluSoft.pl</a></h2>
						</div>

						<h3>Jakie branże mogą skorzystać z rozwiązań <a href="https://www.blusoft.pl/">BluSoft.pl</a>?</h3>
						<p>Czy Twoja firma działa w produkcji, handlu lub innych branżach? Dowiedz się, jak możemy Ci pomóc!</p>
						<p>Rozwiązania <a href="https://www.blusoft.pl/">BluSoft.pl</a> są wszechstronne i dostosowane do różnych branż, w tym produkcji, handlu, usług i wielu innych. Niezależnie od dziedziny, nasze narzędzia mogą zrewolucjonizować i zwiększyć efektywność Twojej firmy.</p>

						<h3>Jak przeniesienie na centralny serwer wpływa na organizację pracy?</h3>
						<p>Czy wiesz, że przenoszenie zadań na centralny serwer może uprościć zarządzanie biurem? Jakie są korzyści dla Twojej branży?</p>
						<p>Przeniesienie na centralny serwer oferowane przez <a href="https://www.blusoft.pl/">BluSoft.pl</a> pozwala na lepszą organizację pracy, ponieważ umożliwia dostęp do środowiska pracy z dowolnego miejsca i urządzenia. To rozwiązanie jest korzystne dla różnych branż, ponieważ upraszcza zarządzanie i współpracę wewnątrz firmy.</p>

						<h3>Jakie są zalety korzystania z narzędzia <a href="https://www.blusoft.pl/e-pulpit24">e-Pulpit24</a>?</h3>
						<p>Czy potrzebujesz elastyczności w pracy? Czy zdajesz sobie sprawę, że dzięki <a href="https://www.blusoft.pl/e-pulpit24">e-Pulpit24</a> możesz pracować w dowolnym miejscu i czasie?</p>
						<p><a href="https://www.blusoft.pl/e-pulpit24">e-Pulpit24</a> to narzędzie oferowane przez <a href="https://www.blusoft.pl/">BluSoft.pl</a>, które umożliwia pracę w trybie online z dowolnego miejsca i urządzenia. To idealne rozwiązanie dla osób potrzebujących elastyczności w czasie i miejscu pracy.</p>

						<h3>Dlaczego przechowywanie danych w chmurze jest bezpieczne?</h3>
						<p>Czy boisz się o bezpieczeństwo danych? Dlaczego przechowywanie w chmurze to gwarancja ochrony przed awariami sprzętu?</p>

						<h3>Jakie są korzyści z optymalizacji oprogramowania dla firm?</h3>
						<p>Czy wiesz, że optymalizacja oprogramowania jest kluczowa dla sukcesu? Jakie są zalety dostosowywania do bieżących potrzeb?</p>

						<h3>Jakie są najważniejsze efekty pracy w jednym miejscu?</h3>
						<p>Czy zastanawiałeś się, jakie korzyści przynosi gromadzenie efektów pracy w jednym miejscu? Jak to ułatwia prezentację wyników?</p>

						<h3>Jak mogę zacząć korzystać z <a href="https://www.blusoft.pl/">BluSoft.pl</a>?</h3>
						<p>Czy jesteś gotów zwiększyć efektywność swojej firmy? Skontaktuj się z nami i dowiedz się, jak możemy Ci pomóc!</p>
						<p>Jeśli chcesz dowiedzieć się, jak zacząć korzystać z <a href="https://www.blusoft.pl/">BluSoft.pl</a> i zwiększyć efektywność swojej firmy, skontaktuj się z naszym zespołem. Nasi eksperci chętnie Ci pomogą dostosować nasze rozwiązania do Twoich potrzeb.</p>
					</div>
				</section>
				<section className="section" id="nasze-marki">
					<div className="w100 flex justify-center">
						<h2>
							Marki BluSoft
						</h2>
					</div>
					<div className="our-brands">
						<BrandCard
							image={EPulpit}
							imageAlt="e-pulpit24.pl"
							short={t("epulpitShort")}
							content={t("epulpitLong")}
						/>
						<BrandCard
							image={BluSerwer}
							imageAlt="BluSerwer"
							short={t("bluserwerShort")}
							content={t("bluserwerLong")}
						/>
						<BrandCard
							image={Blusign}
							imageAlt="BluSerwer"
							short={t("blusignShort")}
							content={t("blusignLong")}
						/>
						<BrandCard
							image={Blustreamtv}
							imageAlt="BluStreamTV"
							short={t("blustreamtvShort")}
							content={t("blustreamtvLong")}
						/>
						<BrandCard
							image={BlustreamAcad}
							imageAlt="BluStream Academy"
							short={t("blustreamacadShort")}
							content={t("blustreamacadLong")}
						/>
						<BrandCard
							image={BluOffice}
							imageAlt="BluOffice"
							short={t("bofficeShort")}
							content={t("bofficeLong")}
						/>
					</div>
				</section>
				<Footer />
			</div>
		)
	}
}

export default withTranslation()(Home);