import React from "react";
import Logo from "./bluholding.webp";
import "./Header.sass";
import { withTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

class Header extends React.Component {
  #backgroundTransition;
  #headerRef = React.createRef();
  #menuRef = React.createRef();
  #svgRef = React.createRef();
  #mainPageCallback;

  constructor(props) {
    super(props);
    this.#mainPageCallback = props.mainPageCallback;
  }

	render() {
    const { t } = this.props;
    return (
      <header className="header">
        <div className="header__block">
          <a href="/">
            <img src="https://www.blusoft.pl/img/logo_brand/bluSoft_white.png" alt="BluHolding logo" />
          </a>
        </div>
        <div className="header__block--mobile" onClick={this.#onMobileMenu}>
          <svg width={40} height={32} className="svg" ref={this.#svgRef}>
            <rect className="svg__rect" width={40} height={5}></rect>
            <rect className="svg__rect" width={40} height={5}></rect>
            <rect className="svg__rect" width={40} height={5}></rect>
          </svg>
        </div>
        <div className="header__block header__options" ref={this.#menuRef}>
          <HashLink to="/#o-nas" className="header__option">{t("aboutUs")}</HashLink>
          <HashLink to="/#nasze-marki" className="header__option">Nasze Marki</HashLink> 
          <a href="#kontakt" className="header__option">{t("contact")}</a>
          <span>&nbsp;</span>
        </div>
      </header>
    );
  }

  #onMobileMenu = () => {
    const menu = this.#menuRef.current;
    menu.classList.toggle("header__options--visible");
    const button = this.#svgRef.current;
    button.classList.toggle("svg");
    button.classList.toggle("svg--active");
  };
}

export default withTranslation()(Header);