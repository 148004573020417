import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next"
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Bluholding from './bluholding.webp';
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Error404 = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "BluSoft 404"
  }, []);

  return (
    <div className="flex justify-center align-center h100">
      <div className="text-center">
        <img src="https://www.blusoft.pl/img/logo_brand/bluSoft_white.png" alt="Bluholding" style={{filter: "invert()"}}/>
        <h1>{t("pageNotFound")}</h1>
        <Link to="/">
          <input type="button" value={t("goToMain")} className="button--primary" />
        </Link>
      </div>
    </div>
  )
}

export default Error404