import React from 'react';
import { withTranslation } from 'react-i18next';
import './BrandCard.sass';

class BrandCard extends React.Component {
  state = {
    isFullText: false
  }
  #image;
  #imageAlt;
  #short;
  #content;
  #cardRef = React.createRef();

  constructor (props) {
    super(props);
    this.#image = props.image;
    this.#imageAlt = props.imageAlt;
    this.#short = props.short;
    this.#content = props.content;
  }

  render () {
    const { t } = this.props;
    return (
      <div className="BrandCard section__block section__block--border" ref={this.#cardRef}>
        <div className="w100">
          <div className="w100 flex justify-center">
            <img src={this.#image} alt={this.#imageAlt} />
          </div>
          <p className="text-center">
          {
            this.#short
          }
          </p>
          {
            <div className="BrandCard__absolute">
              <p>
                {this.#content}
              </p>
              <div className="w100 flex justify-center">
                <input type="button" value={t("less")} onClick={this.#onShowLess} className="BrandCard__button button--primary" />
              </div>
            </div>
          }
        </div>
        <div className="w100 flex justify-center">
        <input type="button" value={t("more")} className="BrandCard__button button--primary" onClick={this.#onShowMore} />
        </div>
      </div>
    )
  }

  #onShowMore = () => {
    this.#onShowLess();
    this.#cardRef.current.classList.add("BrandCard--expanded");
  }

  #onShowLess = () => {
    document.querySelectorAll(".BrandCard--expanded").forEach(elem => {
      elem.classList.toggle("BrandCard--expanded");
    });
  }
}

export default withTranslation()(BrandCard)