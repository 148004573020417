import React from 'react';
import { withTranslation } from 'react-i18next';
import './Footer.sass';
import BluHolding from './../Header/bluholding.webp';
import Mail from './mail.webp';
import Phone from './phone.webp';
import Klaster from './bki.webp';
import Iot from './iot.png';

class Footer extends React.Component {
  #mainPageCallback;

  constructor(props) {
    super(props);
    this.#mainPageCallback = props.mainPageCallback;
  }

  render () {
    const { t } = this.props;
    return (
      <footer className="Footer align-center" id="kontakt">
        <div className="grid--4-1 vgap-60 align-center">
          <div>
            <div className="w100 flex justify-center">
              <a href="/">
                <img className="Footer__img" src="https://www.blusoft.pl/img/logo_brand/bluSoft_white.png" alt="Blu Holding" />
              </a>
            </div>
          </div>
          <div>
            <p className="Footer__text-with-icon">
              <img className="Footer__img" src={Mail} alt="Mail" /> <a href="mailto:krzysztof.chmura@blusoft.pl">krzysztof.chmura@blusoft.pl</a>
            </p>
            <p className="Footer__text-with-icon">
              <img className="Footer__img" src={Phone} alt="Phone" /><a href="tel:+48525222249">(+48) 52 522 22 49&nbsp;&nbsp;&nbsp;&nbsp;</a>
            </p>
            <a href="https://goo.gl/maps/XaKqFyvxeWt1SF666">
              <p>ul. Kijowska 44, 85-703 Bydgoszcz</p>
            </a>
          </div>
          <div className="flex justify-center flex-direction-column align-center">
              <img className="Footer__baner" src={Iot} alt="Blu Game Show" />
              <img className="Footer__img" src={Klaster} alt="Bydgoski Klaster Informatyczny" />
          </div>
          <div>
            <p>Strony Powiązane</p>
            <div className="row m-0">
              <div className="col-sm-6">
                <ul className="list-unstyled quick-links">
                  <li>
                    <a href="https://www.e-pulpit24.pl/" target="_blank">e-Pulpit24.pl</a>
                  </li>
                  <li>
                    <a href="https://bluserwer.pl/" target="_blank">BluSerwer</a>
                  </li>
                  <li>
                    <a href="http://www.bluspace.pl" target="_blank">BluSpace</a>
                  </li>
                  <li>
                    <a href="https://adshoot.pl" target="_blank">Adshoot</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6">
                <ul className="list-unstyled quick-links">
                  <li>
                    <a href="https://360totalsecurity.pl/" target="_blank">360 TotalSecurity</a>
                  </li>
                  <li>
                    <a href="https://bluoffice.pl/" target="_blank">Blu-Office</a>
                  </li>
                  <li>
                    <a href="https://www.podpis-elektroniczny.bydgoszcz.pl" target="_blank">Podpis Elektroniczny</a>
                  </li>
                  <li><a href="https://blustreamtv.pl">BluStream TV</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default withTranslation()(Footer)